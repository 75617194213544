<style lang="sass" scoped>
.container-property
  // z-index: 1000
  .index-nav
    z-index: 1000
  .container-nav
    position: fixed
    left: 0
    top: 0
    width: 70px
    height: 100vh
    z-index: 1000
    background-color: darken(#f8f9fa, 7%)
    // border-right: solid 1px darken(#f8f9fa, 5%)

    .link
      width: 100%
      font-size: 12px
      font-weight: 700
      color: darken(#f8f9fa, 60%) !important
      &.active
        background-color: #f8f9fa

      &:hover
        background-color: #f8f9fa
        text-decoration: none

.container-left
  z-index: 900
  position: fixed
  top: 0px
  left: 70px
  height: 100vh
  // margin-top: 69px
  float: left
  width: 300px

  // position: sticky
  // top: 70px-1px
  // height: 90vh
  .container-left-top
    height: calc(100vh - 60px)
    overflow: scroll

    // background-color: #f8f9fa

  .container-left-bottom
    height: 60px
.container-right
  position: absolute
  right: 0
  // float: right
  // padding-left: 300px
  // margin-top: 69px
  width: calc(100% - 370px)
  height: 100%
  z-index: 1000
  overflow: scroll

.container-customer
  font-size: 12px
  .link
    color: darken(#f8f9fa, 60%)
    font-weight: bold
    line-height: 2rem
    &.active
      color: darken(#f8f9fa, 80%)
      background-color: darken(#f8f9fa, 7%)
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  line-height: 1.7rem
  font-size: 12px
  .link
.container-inactive-top
  position: relative
  top: -6rem
  height: 6rem
  background-color: rgba(248,249,250,.7)
.required
  color: tomato
.container-modal-backdrop
  // background-color: rgba(248,249,250,.5)
  background-color: rgba(0,0,0,.2)
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 100%
  z-index: 2000
.container-modal
  position: fixed
  z-index: 2001
  top: 3rem
  width: 760px
  left: calc(50% - 760px/2)
  .close
    font-size: 24px
  .body
  .text-description
    color: darken(#f8f9fa, 50%)
    font-size: 12px
    font-weight: 600
</style>
<template lang="pug">
div.container-property
  .container-nav
    //- <div class="d-flex flex-column" style='height: 100%'>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="p-2">Flex item</div>
    //-   <div class="mt-auto p-2">Flex item</div>
    //- </div>
    .d-flex.flex-column(style='height: 100%')
      div(style='height: 300px')
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#')
            span(style='font-size: 24px; line-height: 12px; font-weight: 900'): em N
          a.link.p-2.py-4.text-center.text-dark.active(href='#') 고객
          a.link.p-2.py-4.text-center.text-dark(href='#') 메시지
      div.mt-auto()
        .d-flex.flex-column
          a.link.p-2.py-4.text-center.text-dark(href='#') 설정
          a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
          a.link.p-2.py-4.text-center.text-dark(href='#') 도움말



      //- a.link.p-2.py-4.text-center.text-dark(href='#') 설정
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 프로필
      //- a.link.p-2.py-4.text-center.text-dark(href='#') 도움말

  div.container-left
    .container-left-top
      .row.justify-content-center.align-items-center(style='')
        .col
          .p-4.container-customer
            h5 고객
            p 현재 고객 목록이 없습니다.

            .mb-4
            h5 관련 정보
            p 관련 정보가 없습니다.

    .container-left-bottom.border-top.shadow
      a.link.p-3.d-block(href='#') 새 목록 만들기

  div.container-right.bg-white.shadow-sm

  .container-modal-backdrop
  .container-modal
    .body.bg-white.shadow-lg.rounded
      h5.p-4.mb-0.border-bottom
        span.text-muted 빈칸에서 만들기
        b-icon-chevron-right.mx-2
        span 고객
        a.close.float-right(href='#')
          b-icon-x
      //- p.mb-0.text-description.px-4.pt-4 고객이나 관련 정보의 목록을 만들어보세요. 검색부터 메시지 발송 등 고객관리를 체계적으로 할 수 있습니다.

      .p-4
        h5 항목

        //- .shadow-sm
        draggable(v-model='rows' handle='.handle')
          div.p-2(v-for='e in rows' :key='e.key')
            .handle.pr-4.float-left(style="cursor: ns-resize")
              b-icon-arrow-down-up
            strong(v-if='!e.editable') {{e.label}}
            div(v-if='e.editable')
              input.form-control.d-inline.w-50.form-control-sm(type='text' v-model='e.label')
              a.float-right(href='#') 삭제
            //- small.float-right.text-muted ID: 1000
        a(href='#')
          b-icon-plus
          | 항목 추가하기
      .my-4
        .text-center
          a.px-4.py-2.text-muted(href='#') 뒤로
          a.ml-4.px-4.py-2(href='#') 다음
        br



</template>

<script>

import draggable from 'vuedraggable'

export default {
  name: 'index',
  components: {
    draggable,
  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      rows: [
        {
          key: 'code',
          label: '고객ID',
          editable: false,
        },
        {
          key: 'email',
          label: '이메일주소',
          editable: false,
        },
        {
          key: 'phone',
          label: '연락처',
          editable: false,
        },
        {
          key: 'name',
          label: '이름',
          editable: false,
        },
        {
          key: '',
          label: '닉네임',
          editable: true,
        },
        {
          key: '',
          label: '',
          editable: true,
        },
      ]
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
